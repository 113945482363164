
// window.DataTable = datatable();

import MojoknowsController from "./mojoknows_controller.js"

import {Popover, Toast, Tooltip} from 'bootstrap'
import TomSelect from "tom-select";

// Connects to data-controller='login'
export default class extends MojoknowsController {

  connect() {

    super.connect(); // call the parent class

    console.log('Customer Tasks (edit mode) connected');
    // dynamic data entry
    $('#customer_tasks_table').editableTableWidget();

  }

}

