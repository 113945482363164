
// app/javascript/controllers/login_controller.js
import { Controller } from '@hotwired/stimulus'
import { Popover, Tooltip } from 'bootstrap'

// Connects to data-controller='login'
export default class extends Controller {

  connect() {
    console.log('Bellaring Booking Controller Connected');
    $('#location').on('change', function(e) {
      const val = $('#location').val();
      console.log('location changed to : '+val);
    });
    $('#login_link').on('click', function() {
      $('#forgot_password_part').slideUp();
      $('#login_part').slideDown();
      $('#login_button').html('Login');
      $('#email').removeAttr('required');
      $('#username').prop('required',true);
      $('#pwd').prop('required',true);
    });
    $('#reset_password_link').on('click', function() {
      $('#forgot_password_part').slideDown();
      $('#login_part').slideUp();
      $('#login_button').html('Request email')
      $('#email').prop('required',true);
      $('#username').removeAttr('required');
      $('#pwd').removeAttr('required');
    });

    setTimeout(function() {
      var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        console.log('adding popover to ' + popoverTriggerEl.id);
        return new Popover(popoverTriggerEl)
      })

      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        // console.log('adding tooltip to ' + tooltipTriggerEl.id);
        return new Tooltip(tooltipTriggerEl)
      })
    },1000);
  }

  show() {
    const toastElement = document.getElementById('liveToast')
    const toast = new Toast(toastElement)
    toast.show();
  }
}

