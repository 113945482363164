
// app/javascript/controllers/toast_controller.js
import { Controller } from '@hotwired/stimulus'
import {Popover, Toast, Tooltip} from 'bootstrap'
import TomSelect from 'tom-select';
import {Dropzone} from "dropzone";

// Connects to data-controller='toast'
export default class extends Controller {

  connect() {

    super.connect();
    
    setTimeout(function() {
      var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        console.log('adding popover to ' + popoverTriggerEl.id);
        return new Popover(popoverTriggerEl)
      })

      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        // console.log('adding tooltip to ' + tooltipTriggerEl.id);
        return new Tooltip(tooltipTriggerEl)
      })
    },1000);

    if (mobileCheck()) {
      $('#extra_info').show();
    }

    $('#customer_trip_passport_country').on('change', function() {
      var value = this.value;
      if (!value.length) return;
      if (value == 'Deutschland') {
        $('#telstra_complaint_div').show();
        console.log('Showing Telstra Complaint Div');
      }
      else
      {
        $('#telstra_complaint_div').hide();
        console.log('Hiding Telstra Complaint Div');
      }
    });



    $('#order_infos_form').submit(function (e) {


        e.preventDefault(); //this will prevent the default submit

        // your code here
        $('#order_infos_form_button').val("Speichere...")
        $('#submit_spinner').show();

        $(this).unbind('submit').submit(); // continue the submit unbind preventDefault
    });


    $( 'select' ).each(function( index ) {
      const sel = $(this);
      const sel_id = sel.attr('id');
      if (sel_id.indexOf('phone_model') > 0) {
        // set up special tom select with remote lookup for phone model
        // console.log('remote tom-selecting '+sel_id);

        new TomSelect(sel,{
          valueField: 'id',
          labelField: 'name',
          searchField: 'name',
          // fetch remote data
          load: function(query, callback) {
            var url = location.protocol + '//' + location.host;
            url = url + '/lookup_phone_models?q=' + encodeURIComponent(query);
            console.log('checking '+url);
                  fetch(url)
                      .then(response => response.json())
                      .then(json => {
                        // console.log(json.items);
                        callback(json.items);
                      }).catch(()=>{
                    callback();
                  });
          },
          onChange: function(value) {
            const sel_id = this.input.id;
            const name_id = sel_id.replace('_id_','_name_');
            const selval = $('#'+sel_id+' option:selected').text()
            $('#'+name_id).val(selval);
          }
        });


      }
      else
      {
        console.log('tom-selecting '+sel_id);
        new TomSelect(sel, {});
      }

    });


    $('.dropzone').each(function() {
      const elid = $(this).attr('id');
      console.log('adding dropzone for '+elid);
      const dropzone = new Dropzone('form#' + elid, {
        url: '/admin/fileupload/'+$(this).attr('id'),
        maxFiles: 1,
        maxFilesize: 15,
        maxThumbnailFilesize: 15,
        acceptedFiles: ".jpg, .jpeg, .png, .heic, .pdf",
        thumbnailWidth: "250",
        thumbnailHeight: "250",
        success: function(file, response){
          //console.log('using thumbnail url : '+response.thumbnail_url);

          this.createThumbnailFromUrl(file, response.thumbnail_url);
          console.log('setting img_'+elid+' to '+response.thumbnail_url+'?v='+Date.now());
          $('#img_'+elid).attr('src',response.thumbnail_url+'?v='+Date.now());
          $('#img_'+elid).show();
          $('#rotate_'+elid).show();
          $('#toast_title').html('Success!');
          $('#toast_subtitle').html('');
          $('#toast_body').html('The photo was successfully uploaded.')
          const toastElement = document.getElementById("liveToast");
          const toast = new Toast(toastElement);
          toast.show();
        },
        init: function() {
          this.on("success", function(file) {
            $('.dz-image').css({"width":"100%", "height":"auto"});
            //let blobURL = URL.createObjectURL(file);
            //convertHeicImage(blobURL, file)
          })
        },
        // remove the thumbnail after we uploaded it
        complete: function(file) {
          this.removeFile(file);
        },
        headers: {
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        }});
    });
    setTimeout(function() {
      $('.dz-button').html('Zum hochladen der Datei (PDF oder Foto) hier klicken oder die Datei hier hinschieben')
    }, 500);
  }

  show() {
    const toastElement = document.getElementById('liveToast')
    const toast = new Toast(toastElement)
    toast.show();
  }
}

