
import {Popover, Toast, Tooltip} from 'bootstrap'
import {Controller} from "@hotwired/stimulus";


export default class extends Controller {

  connect() {

    console.log('Wind Controller loaded. needs to use turbo !');
    setTimeout(() => {
      console.log("Reloading...");
      window.location.reload();
    }, 60000)

  }


}

