import MojoknowsController from "./mojoknows_controller.js"

import {Popover, Toast, Tooltip} from 'bootstrap'
import TomSelect from "tom-select";
import {DataTable} from "simple-datatables"

export default class extends MojoknowsController {

  connect() {

    super.connect(); // call the parent class

    // determine default sort order
    var loc = window.location.href;
    let sort_col = 0; // title
    let sort_order = 'asc';
    console.log('location is '+loc+' - sorting by col '+sort_col+' '+sort_order);
    const dataTable = new DataTable("#products_table", {
      searchable: true,
      fixedHeight: true,
      columns: [
        { select: sort_col, sort: sort_order }
      ],
      paging: false
    });


  }


}

