
// parent class for all the stimulus controllers for mojoknows
// just so we can keep common stuff that's used (almost) everywhere in one spot
// app/javascript/controllers/mojoknows_controller.js
import { Controller } from '@hotwired/stimulus'
import {Popover, Toast, Tooltip} from 'bootstrap'
import TomSelect from "tom-select";

// weirdly this import for datatables is now no longer required...ES6 is confusing
// import datatable from 'datatables.net-bs5';
// import 'datatables.net-buttons-bs5';
// import 'datatables.net';
// import dt from 'datatables.net-bs5';
// dt(window, window.$);
// I had datatables working !!!! and then they stopped working. I thought I had checked in the version that worked but it seems I did not. fuckin frustrating !!!
// now trying simple datatables...and they work https://github.com/fiduswriter/Simple-DataTables/wiki/Getting-Started#initialise
import {DataTable} from "simple-datatables"

export default class extends Controller {

    connect() {

        console.log('Parent MojoKnows Controller connected');
        setTimeout(function() {
            var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
            var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                console.log('adding popover to ' + popoverTriggerEl.id);
                return new Popover(popoverTriggerEl)
            })

            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                // console.log('adding tooltip to ' + tooltipTriggerEl.id);
                return new Tooltip(tooltipTriggerEl)
            })
        },1000);

        $('#clock_icon').on('click', function() {
            $('#clocks').slideToggle();
        });

        // inline table editing

        $('table td').on('change', function(evt, newValue) {
            // do something with the new cell value
            const element_id = evt.target.id;
            // console.log('evt is <'+evt+'>');
            console.log('element_id is <'+element_id+'>');
            if (element_id == 'message_template_id')
            {
                return;
            }
            const val = newValue.trim();
            console.log('element <'+element_id+'> was changed to <'+val+'>');
            $.ajax({
                url: '/admin/update_field',
                data: {
                    element: element_id,
                    value: val
                },
                type: 'POST',
                error: function () {
                    alert('Sorry, something went wrong updating this value.');
                    callback();
                },
                success: function (res) {
                    if (res.error.length) {
                        console.log('backend update for ' + element_id + ' with new value <' + newValue + '> unsuccessful: '+res.error);
                        $(evt.target).text(res.value);
                        $('#toast_title').html('Sorry!');
                        $('#toast_subtitle').html('We could not make this change for you.');
                        $('#toast_body').html(res.message);
                        const toastElement = document.getElementById("liveToast");
                        const toast = new Toast(toastElement);
                        toast.show();
                    }
                    else {
                        if (element_id.endsWith('_note_new'))
                        {
                            window.location.reload();
                        }
                        else {
                            if (res.message.length) {
                                var subtitle = '';
                                if (res.subtitle.length) {
                                    subtitle = res.subtitle;
                                }
                                var title = 'Success!';
                                if (res.title.length) {
                                    title = res.title;
                                }

                                $('#toast_title').html(title)
                                $('#toast_subtitle').html(subtitle)
                                $('#toast_body').html(res.message)
                                const toastElement = document.getElementById("liveToast");
                                const toast = new Toast(toastElement);
                                toast.show();
                            }
                            //console.log('val.length is '+val.length);
                            if (val.length == 0) {
                                $(evt.target).addClass("table-warning");
                            }
                            console.log('backend update for ' + element_id + ' with new value <' + newValue + '> successful.')
                        }
                        const nowval = $(evt.target).text().trim();
                        if (nowval.length > 0) {
                            $(evt.target).removeClass("table-warning");
                        }
                    }
                }
            });
            return true; // accept change
        });
    }


}