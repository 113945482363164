
// app/javascript/controllers/login_controller.js
import { Controller } from '@hotwired/stimulus'
import { Toast, Popover, Tooltip } from 'bootstrap'
import Awesomplete from 'awesomplete-es6';

// Connects to data-controller='login'
export default class extends Controller {

  connect() {

    var input = document.getElementById("usersearch");

    input.placeholder = "Loading data - please wait...";
    // Show label but insert value into the input:

    $.getJSON("/customers.json", function(data){
      input.placeholder = "Data loaded...";
      new Awesomplete(input, {
        list: data,
      });

      $('#usersearch').on('awesomplete-selectcomplete',function(x) {
        var cid = $('#usersearch').val();
        window.location.href = '/customers/'+cid;
      });

      input.placeholder = "Type to find customers";
    });


  }

}

