
// window.DataTable = datatable();

import MojoknowsController from "./mojoknows_controller.js"

import {Popover, Toast, Tooltip} from 'bootstrap'
import TomSelect from "tom-select";

// weirdly this import for datatables is now no longer required...ES6 is confusing
// import datatable from 'datatables.net-bs5';
// import 'datatables.net-buttons-bs5';
// import 'datatables.net';
// import dt from 'datatables.net-bs5';
// dt(window, window.$);
// I had datatables working !!!! and then they stopped working. I thought I had checked in the version that worked but it seems I did not. fuckin frustrating !!!
// now trying simple datatables...and they work https://github.com/fiduswriter/Simple-DataTables/wiki/Getting-Started#initialise
import {DataTable} from "simple-datatables"

// Connects to data-controller='login'
export default class extends MojoknowsController {

  connect() {

    super.connect(); // call the parent class

    console.log('Customer Tasks (View mode ) connected');

    const dataTable = new DataTable("#customer_tasks_table", {
      searchable: true,
      fixedHeight: true,
      columns: [
        // Sort the date column in ascending order
        {select: 0, sort: "asc"}
      ],
      order: [[ 1, "asc"]],
      perPage: 100,
      perPageSelect: [ 20, 50, 100, 500 ],
    });
  }

}

