
// app/javascript/controllers/login_controller.js
import { Controller } from '@hotwired/stimulus'
import { Popover, Tooltip } from 'bootstrap'
import TomSelect from "tom-select";

// weirdly this import for datatables is now no longer required...ES6 is confusing
// import datatable from 'datatables.net-bs5';
// import 'datatables.net-buttons-bs5';
// import 'datatables.net';
// import dt from 'datatables.net-bs5';
// dt(window, window.$);
// I had datatables working !!!! and then they stopped working. I thought I had checked in the version that worked but it seems I did not. fuckin frustrating !!!
// now trying simple datatables...and they work https://github.com/fiduswriter/Simple-DataTables/wiki/Getting-Started#initialise
import {DataTable} from "simple-datatables"

// window.DataTable = datatable();

// Connects to data-controller='login'
export default class extends Controller {

  connect() {

    $('#clock_icon').on('click', function() {
      $('#clocks').slideToggle();
    });

    setTimeout(function() {
      var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        console.log('adding popover to ' + popoverTriggerEl.id);
        return new Popover(popoverTriggerEl)
      })

      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        // console.log('adding tooltip to ' + tooltipTriggerEl.id);
        return new Tooltip(tooltipTriggerEl)
      })
    },1000);

    const dataTable = new DataTable("#customers_table", {
      searchable: true,
      fixedHeight: true,
      columns: [
        // Sort the second column in ascending order
        { select: 3, sort: "desc" }
      ],
      perPage: 100,
      perPageSelect: [ 20, 50, 100, 500 ]
    });
  }

}

