import MojoknowsController from "./mojoknows_controller.js"

import {Popover, Toast, Tooltip} from 'bootstrap'
import TomSelect from "tom-select";
import {DataTable} from "simple-datatables"

export default class extends MojoknowsController {

  connect() {

    super.connect(); // call the parent class

    // determine default sort order
    var loc = window.location.href;
    let sort_col = 4; // Need Shipping
    let sort_order = 'asc';
    if (loc.indexOf('Arriving+Soon') > 0) {
      sort_col = 7;
    }
    if (loc.indexOf('New+Orders') > 0) {
      sort_col = 2;
      sort_order = "desc";
    }
    console.log('location is '+loc+' - sorting by col '+sort_col+' '+sort_order);
    const dataTable = new DataTable("#orders_table", {
      searchable: true,
      fixedHeight: true,
      perPage: 100,
      perPageSelect: [ 20, 50, 100, 500 ],
      columns: [
        { select: sort_col, sort: sort_order }
      ]
    });


    $( 'select' ).each(function( index ) {
      const sel = $(this);
      const sel_id = sel.attr('id');
      // do not mess with the datatable selects otherwise they will show up out of whack
      const selids = [ 'hola_order_type_select' , 'supplier' , 'passport_country' ];

      var is_in_it = selids.includes(sel_id);
      if (is_in_it) {
        console.log('configuring select ' + sel_id);

        new TomSelect(sel, {
          onChange: function (value) {
            console.log('onChange('+value+')');
            let url = '/hola';

            let mode = '';
            selids.forEach(function(value, index, array)
            {
              var yourSelect = document.getElementById(value);
              var yourValue = yourSelect.options[ yourSelect.selectedIndex ].value;
              console.log(yourSelect+' has value <'+yourValue+'>');
              if (value == 'hola_order_type_select') {
                mode = yourValue;
                url = url + '?' + value + '='+yourValue;
              }
              else
              {
                console.log('mode is <'+mode+'>');
                if ((mode.indexOf('Arriving') >= 0) || (mode.indexOf('Departing') >= 0)) {
                  if (yourValue.length > 0) {
                    url = url + '&' + value + '=' + yourValue;
                  }
                }
              }
            });
            console.log('url : '+url);
            window.location = url;
          }
        });
      } else {
        console.log('tom-selecting ' + sel_id);
        new TomSelect(sel, {});
      }
    });



  }


}

