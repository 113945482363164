
// app/javascript/controllers/login_controller.js
import { Controller } from '@hotwired/stimulus'
import { Toast, Popover, Tooltip } from 'bootstrap'
// import { DarkEditable } from './dark-editable';
import { Dropzone } from "dropzone";
import {DataTable} from "simple-datatables";
// import heic2any from "heic2any";

// import { Clipboard } from 'clipboard'

// Connects to data-controller='login'
export default class extends Controller {

  connect() {

    const dataTable = new DataTable("#sales_details_table", {
      searchable: true,
      fixedHeight: true,
      columns: [
        // Sort the date column in ascending order
        {select: 0, sort: "asc"}
      ],
      order: [[ 1, "asc"]],
      perPage: 100,
      perPageSelect: [ 20, 50, 100, 500,1000 ],
    });

    setTimeout(function() {
      var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        console.log('adding popover to ' + popoverTriggerEl.id);
        return new Popover(popoverTriggerEl)
      })

      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        // console.log('adding tooltip to ' + tooltipTriggerEl.id);
        return new Tooltip(tooltipTriggerEl)
      })
    },1000);

  }

  show() {
    const toastElement = document.getElementById('liveToast')
    const toast = new Toast(toastElement)
    toast.show();
  }
}

