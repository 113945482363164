
// app/javascript/controllers/login_controller.js
import { Controller } from '@hotwired/stimulus'
import {Popover, Toast, Tooltip} from 'bootstrap'
import Awesomplete from 'awesomplete-es6';

// Connects to data-controller='login'
export default class extends Controller {

  connect() {
    console.log('Signup Controller Connected');

    var input = document.getElementById("location");
    // don't set a list - that's done dynamically
    var awesomplete = new Awesomplete(input, {
      minChars: 1,
      autoFirst: true
    });

    var last_value = '';

    $('#location').on('input', function(e) {
      const val = $('#location').val();
      console.log('location changed to : '+val);
      setTimeout(function() {
        const val2 = $('#location').val();
        if ((val == val2) && (val.length >= 5))
        {
          console.log('Triggering search for <'+val+'>');
          $.ajax({
            url: '/letsfixit/find_address',
            data: {
              query: val
            },
            type: 'POST',
            error: function () {
              alert('Sorry, something went wrong when we tried to look up valid addresses for ' + val);
              callback();
            },
            success: function (res) {
              console.log('got back '+res.message+' : '+res.results);
              awesomplete.list = res.results;
            }
          });
        }
      },500);
    });

    setTimeout(function() {
      var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
      var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
        console.log('adding popover to ' + popoverTriggerEl.id);
        return new Popover(popoverTriggerEl)
      })

      var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
      var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
        // console.log('adding tooltip to ' + tooltipTriggerEl.id);
        return new Tooltip(tooltipTriggerEl)
      })
    },1000);
  }

  show() {
    const toastElement = document.getElementById('liveToast')
    const toast = new Toast(toastElement)
    toast.show();
  }
}

