//import 'datatables.net';
//import dt from 'datatables.net-bs5';
//dt(window, $);
// I had datatables working !!!! and then they stopped working. I thought I had checked in the version that worked but it seems I did not. fuckin frustrating !!!

import 'simple-datatables'

$(function() {
    // window.DataTable = DataTable;
    console.log('DataTables imported');
});

