
// app/javascript/controllers/login_controller.js
import { Controller } from '@hotwired/stimulus'
import {Popover, Toast, Tooltip} from 'bootstrap'
import {Dropzone} from "dropzone";

// Connects to data-controller='login'
export default class extends Controller {

    connect() {
        setTimeout(function() {
            var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
            var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                console.log('adding popover to ' + popoverTriggerEl.id);
                return new Popover(popoverTriggerEl)
            })

            var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
            var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
                // console.log('adding tooltip to ' + tooltipTriggerEl.id);
                return new Tooltip(tooltipTriggerEl)
            })
        },1000);

        $('.dropzone').each(function() {
            const elid = $(this).attr('id');
            console.log('adding dropzone for '+elid);
            const dropzone = new Dropzone('form#' + elid, {
                url: '/admin/fileupload/'+$(this).attr('id'),
                maxFiles: 1,
                maxFilesize: 15,
                maxThumbnailFilesize: 15,
                acceptedFiles: ".jpg, .jpeg, .png, .heic, .pdf",
                thumbnailWidth: "250",
                thumbnailHeight: "250",
                success: function(file, response){
                    //console.log('using thumbnail url : '+response.thumbnail_url);
                    window.location.reload();
                },
                init: function() {
                    this.on("success", function(file) {
                        $('.dz-image').css({"width":"100%", "height":"auto"});
                        //let blobURL = URL.createObjectURL(file);
                        //convertHeicImage(blobURL, file)
                    })
                },
                // remove the thumbnail after we uploaded it
                complete: function(file) {
                    this.removeFile(file);
                },
                headers: {
                    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
                }});
        });
    }

}

